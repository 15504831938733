import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { useLotPageMultiStore } from 'stores/LotPageStore';

import NavigationItem from './NavigationItem';

import s from './Navigation.module.scss';
import { isLotValueModel } from 'utils/lot';

type ActiveSection =
  | ElementId.lotPageCommonDesktop
  | ElementId.lotPagePropertyCharacteristicsDesktop
  | ElementId.lotPageDocsDesktop;

const Navigation = ({ onScroll, grid = false }: { onScroll: (id: ElementId) => void; grid?: boolean }) => {
  const { lot } = useLotPageMultiStore();
  const [activeSection, setActiveSection] = React.useState<ActiveSection>(ElementId.lotPageCommonDesktop);
  const refSections = React.useRef<null | HTMLDivElement>(null);
  const refSectionsList = React.useRef<null | NodeListOf<Element>>(null);
  const refObserver = React.useRef<null | IntersectionObserver>(null);
  const handleItemClick = React.useCallback((value: ActiveSection) => {
    setActiveSection(value);
    onScroll(value);
  }, []);

  const onChangeNavigationActive = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
        setActiveSection(entry.target.id as ActiveSection);
      }
    });
  };

  const initObserver = () => {
    refObserver.current = new IntersectionObserver(onChangeNavigationActive, {
      threshold: 1,
    });
    if (refSections && refSections.current && refObserver && refObserver.current) {
      refSectionsList.current = document.querySelectorAll('.section-navigation');
      refSectionsList.current.forEach((section) => {
        refObserver.current?.observe(section);
      });
    }
  };

  const destroyObserver = () => {
    if (refSectionsList.current) {
      refSectionsList.current.forEach((section) => {
        refObserver.current?.unobserve(section);
      });
    }
  };

  React.useEffect(() => {
    initObserver();
    return () => destroyObserver();
  }, []);

  return (
    <Space className={`${s.navigation} ${grid ? s.grid : ''}`} direction="vertical" size={32}>
      <div className={s.navigation__wrap__items} ref={refSections}>
        <Space direction="vertical" size={8}>
          <NavigationItem
            title="Сведения о лоте"
            onClick={() => handleItemClick(ElementId.lotPageCommonDesktop)}
            selected={false}
            type="title"
          />

          <NavigationItem
            id={ElementId.lotPageCommonDesktop}
            title="Общие сведения"
            onClick={() => handleItemClick(ElementId.lotPageCommonDesktop)}
            selected={activeSection === ElementId.lotPageCommonDesktop}
          />
          <NavigationItem
            id={ElementId.lotPagePropertyCharacteristicsDesktop}
            title="Характеристики имущества"
            onClick={() => handleItemClick(ElementId.lotPagePropertyCharacteristicsDesktop)}
            selected={activeSection === ElementId.lotPagePropertyCharacteristicsDesktop}
          />

          {isLotValueModel(lot) && lot.value.documents.length > 0 && (
            <NavigationItem
              id={ElementId.lotPageDocsDesktop}
              title="Документы лота"
              onClick={() => handleItemClick(ElementId.lotPageDocsDesktop)}
              selected={activeSection === ElementId.lotPageDocsDesktop}
            />
          )}
        </Space>
      </div>
    </Space>
  );
};

export default observer(Navigation);
