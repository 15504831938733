import { Breadcrumb, Space } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { PageContainer } from 'components/PageContainer';
import { routerUrls } from 'config/routes';
import { LotPageProvider, LotPublicPageProvider } from 'stores/LotPageStore/context';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';

import Application from './Application';
import CommonInfo from './CommonInfo';
import LotImagesCarousel from './LotImagesCarousel';
import LotTimeline from './LotTimeline';
import MainInfo from './MainInfo';
import Header from './MainInfo/Header';
import MobileCarousel from './MobileCarousel';
import MobileTimeline from './MobileTimeline';

import s from './LotPage.module.scss';
import MobileHeader from './MainInfo/MobileHeader/MobileHeader';
import { LotsRecommended } from './LotsRecommended/LotsRecommended';
import { useParams } from 'react-router-dom';

type LotPageProps = {
  className?: string;
};

const LotPage = ({ className }: LotPageProps): React.ReactElement<LotPageProps> => {
  const { userStore } = useRootStore();
  const params = useParams();
  const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
    { title: 'Страница лота' },
  ];

  const {
    onboardingStore: { isOnboardingPassedModel, isInitialOnboardingPassedModel, resumeOnboardingOnLotPage },
  } = useRootStore();

  React.useEffect(() => {
    if (!isOnboardingPassedModel.value && isInitialOnboardingPassedModel.value) {
      resumeOnboardingOnLotPage();
    }
  }, []);

  const InnerProvider = userStore.authorized ? LotPageProvider : LotPublicPageProvider;

  return (
    <InnerProvider>
      <PageContainer className={cn(s['lot-page'], className)}>
        <Breadcrumb
          className={s['lot-page__breadcrumbs']}
          itemRender={breadcrumbItemsRender}
          items={BREADCRUMB_ITEMS}
        />
        <Header className={s['lot-page__header']} />
        <MobileCarousel />
        <MobileHeader />
        <Space size="large" className={s['lot-page__content']}>
          <LotImagesCarousel className={s['lot-page__carousel']} />
          <MainInfo showPrintPdf={true} />
        </Space>
        <LotTimeline className={s['lot-page__timeline']} />
        <MobileTimeline className={s['lot-page__slider']} />
        <Application className={s['lot-page__application']} />
      </PageContainer>
      <CommonInfo className={s['lot-page__common-info']} />
      {params?.id && <LotsRecommended id={Number(params.id)} />}
    </InnerProvider>
  );
};

export default observer(LotPage);
