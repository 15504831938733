import { LotModel } from 'models/LotModel';
import { ValueModel } from 'models/ValueModel';

export const isLotModel = (b: unknown): b is LotModel => {
  return (b as LotModel).priceStepOfTotalFormatted !== undefined;
};

export const isLotValueModel = (b: unknown): b is ValueModel<LotModel> => {
  return (b as ValueModel<LotModel>).value.priceStepOfTotalFormatted !== undefined;
};
