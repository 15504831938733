import { Button, Input, Modal, Space, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { LINKS } from 'config/links';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ValueModel } from 'models/ValueModel';

import s from './FormModal.module.scss';
import { BaseInput } from 'components/BaseInput';
import { CloseOutlined, DeleteFilled, FileAddOutlined, FileImageOutlined } from '@ant-design/icons';
import { useSwipeable } from 'react-swipeable';

type FormModalProps = {
  opened: boolean;
  close: () => void;
  onSubmit: () => void;
  name: ValueModel<string>;
  email: ValueModel<string>;
  description: ValueModel<string>;
  maxDescriptionLength: number;
  submitStage: LoadingStageModel;
  hasErrors: boolean;
  isValidateOnChange: boolean;
  className?: string;
  file: ValueModel<File | null>;
};

const FormModal = ({
  opened,
  close,
  onSubmit,
  name,
  email,
  description,
  maxDescriptionLength,
  submitStage,
  hasErrors,
  isValidateOnChange,
  className,
  file,
}: FormModalProps): React.ReactElement<FormModalProps> => {
  const refInput = React.useRef<HTMLInputElement | null>(null);

  const handlers = useSwipeable({
    onSwipedDown: () => {
      close();
    },
    delta: { down: 100 },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      file.change(e.target.files[0]);
    }
  };

  const handleFileClear = () => file.change(null);

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      name.change(event.target.value);

      if (isValidateOnChange) {
        name.validate();
      }
    },
    [name, isValidateOnChange],
  );

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      email.change(event.target.value);

      if (isValidateOnChange) {
        email.validate();
      }
    },
    [email, isValidateOnChange],
  );

  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      description.change(event.target.value);

      if (isValidateOnChange) {
        description.validate();
      }
    },
    [description, isValidateOnChange],
  );

  const handleTextareaKeydown = React.useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // отмена всплытия по Enter
    event.stopPropagation();
  }, []);

  useKeyboardEvent('Enter', onSubmit, opened);

  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={cn(s.popup, className)}
      open={opened}
      footer={null}
      closeIcon={null}
      onCancel={close}
      width={640}
      centered
    >
      <Space className={s.popup__body_inner} direction="vertical" size={24} {...handlers}>
        <div className={s.popup__header}>
          <Typography.Title className={s.popup__title} level={2}>
            Сообщить о проблеме
          </Typography.Title>
          <button role="button" className={s.but__close} onClick={close}>
            <CloseOutlined />
          </button>
        </div>
        <Space className={s['popup__input-wrapper']} direction="vertical" size={0}>
          <BaseInput className={s['popup__input__wrap']} title="Имя">
            <Input
              className={cn(s['popup__input'], s['popup__input_large'])}
              onChange={handleNameChange}
              placeholder="Ваше имя"
              value={name.value}
              status={name.isError ? 'error' : undefined}
            />
          </BaseInput>
          <Typography.Text type="danger">{name.error}</Typography.Text>
        </Space>
        <Space className={cn(s['popup__input-wrapper'], s['popup__input_large'])} direction="vertical" size={0}>
          <BaseInput className={s['popup__input__wrap']} title="Email">
            <Input
              className={cn(s['popup__input'], s['popup__input_large'])}
              onChange={handleEmailChange}
              placeholder="Ваш Email"
              value={email.value}
              status={email.isError ? 'error' : undefined}
            />
          </BaseInput>
          <Typography.Text type="danger">{email.error}</Typography.Text>
        </Space>
        <Space className={s['popup__input-wrapper']} direction="vertical" size={0}>
          <BaseInput className={s['popup__input__wrap']} title="Описание проблемы или вопроса">
            <Input.TextArea
              className={cn(s['popup__input'], s['popup__input-textarea'])}
              onChange={handleDescriptionChange}
              onKeyDown={handleTextareaKeydown}
              placeholder="Введите ваш вопрос или опишите проблему"
              value={description.value}
              status={description.isError ? 'error' : undefined}
            />
          </BaseInput>
          <div className={s['popup__textarea-footer']}>
            <Typography.Text type="danger">{description.error}</Typography.Text>
            <Typography.Text
              className={cn(s['popup__chars-counter'], description.isError && s['popup__chars-counter_error'])}
              type="secondary"
            >
              {description.value.length}&nbsp;/&nbsp;{maxDescriptionLength}
            </Typography.Text>
          </div>
          <div className={s.files}>
            <div className={s.files__list}>
              <input id="file" style={{ display: 'none' }} type="file" onChange={handleFileChange} ref={refInput} />
              {file.value ? (
                <FileImageOutlined className={s.files__icon} width={24} height={24} />
              ) : (
                <button className={s.files__add} onClick={() => refInput.current?.click()}>
                  <FileAddOutlined />
                </button>
              )}
            </div>
            {file.value && (
              <button className={s.files__del} onClick={handleFileClear}>
                <DeleteFilled />
              </button>
            )}
          </div>
          <div className={s.action}>
            <Button
              className={s['popup__submit-button']}
              type="primary"
              onClick={onSubmit}
              loading={submitStage.isLoading}
              disabled={hasErrors}
            >
              Отправить
            </Button>
          </div>
        </Space>
      </Space>
    </Modal>
  );
};

export default observer(FormModal);
