import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ElementId } from 'config/ids';
import { useLotPageMultiStore } from 'stores/LotPageStore';

import s from './MobileCarousel.module.scss';
import { useWidth } from 'hooks/useWidth';
import { ImageModel } from 'models/ImageModel';
import defaultImage from 'assets/defaultImage.png';
import { ILotImage } from 'entities/lot';

const MobileCarousel: React.FC = ({ className }: { className?: string }) => {
  const { lot } = useLotPageMultiStore();
  const { width = window.innerWidth } = useWidth();

  const size = React.useMemo(() => {
    if (width >= 768) {
      return CarouselImageSize.xl;
    }
    return CarouselImageSize.sm;
  }, [width]);

  const empty_images =
    'image_count' in lot
      ? lot.image_count === 0
        ? []
        : (Array((lot.image_count as number) - 1)
            .fill(0)
            .map((_, index) =>
              ImageModel.fromJson(
                {
                  type: 'jpg',
                  url: defaultImage,
                },
                index,
                '',
                false,
              ),
            ) as ILotImage[])
      : [];

  return (
    <div className={s.carousel} id={ElementId.lotPageImageCarouselMobile}>
      <ImgCarousel
        className={className + ' lot-card-image'}
        images={lot.value.images ? [...lot.value.images, ...empty_images] : []}
        imgSize={size}
        withoutThumbnails
        showBullets={true}
      />
    </div>
  );
};

export default React.memo(MobileCarousel);
