import { apiUrls } from 'config/apiUrls';
import { ILotImage, LotImage } from 'entities/lot';

// Размер превью на списке лотов
export const LIST_PREVIEW_SIZE = 360;

// Размер превью в деталке лота
export const DETAIL_PREVIEW_SIZE = 1000;

export class ImageModel implements ILotImage {
  readonly id;
  readonly fullUrl;
  readonly listPreviewUrl;
  readonly detailPreviewUrl;
  readonly type;
  readonly alt;

  constructor({ id, fullUrl, listPreviewUrl, detailPreviewUrl, type, alt }: ILotImage) {
    this.id = id;
    this.fullUrl = fullUrl;
    this.listPreviewUrl = listPreviewUrl;
    this.detailPreviewUrl = detailPreviewUrl;
    this.type = type;
    this.alt = alt;
  }

  static fromJson(raw: LotImage, id: number, alt?: string, resize: boolean = true): ImageModel {
    const fullUrl = raw?.url || raw?.key || '';
    return {
      id,
      type: raw.type,
      fullUrl: fullUrl,
      listPreviewUrl: resize ? apiUrls.resizeImage({ w: LIST_PREVIEW_SIZE, url: fullUrl }) : fullUrl,
      detailPreviewUrl: resize ? apiUrls.resizeImage({ w: DETAIL_PREVIEW_SIZE, url: fullUrl }) : fullUrl,
      alt: alt ?? '',
    };
  }
}
