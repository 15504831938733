import { Space, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './Section.module.scss';

type SectionProps = {
  className?: string;
  contentClassName?: string;
  title: string;
  id?: string;
  grid?: boolean;
  subtitle?: React.ReactNode | string;
  hideTitle?: boolean;
};

const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  children,
  contentClassName,
  className,
  title,
  subtitle,
  hideTitle = false,
  id,
  grid = false,
}) => {
  return (
    <div id={id} className={cn(className, s.section)}>
      {!hideTitle ? (
        <Typography.Title className={cn(s.section__title, grid && s.pdf)} level={3}>
          {title}
          {subtitle}
        </Typography.Title>
      ) : (
        <div>{subtitle}</div>
      )}

      <Space className={cn(s.section__content, contentClassName)} direction="vertical">
        {children}
      </Space>
    </div>
  );
};

export default React.memo(Section);
