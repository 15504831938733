import { action, computed, makeObservable, observable } from 'mobx';

import { IList } from 'types/list';

import { LoadingStageModel } from './LoadingStageModel';
import { ValueModel } from './ValueModel';

export class ListModel<T, C extends string | number | symbol = string> implements IList<T, C> {
  private _keys: C[];

  private _entities: Map<C, T>;
  private _isAllLoaded: boolean;

  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly hasMore: ValueModel<boolean> = new ValueModel<boolean>(true);
  readonly isInitialLoading: ValueModel<boolean> = new ValueModel<boolean>(true);
  readonly isReplaceLoading: ValueModel<boolean> = new ValueModel<boolean>(true);
  // Для списка с пагинацией здесь хранится не текущая длина списка, а полная длина
  readonly total: ValueModel<number | null> = new ValueModel<number | null>(null);
  // Полная длина списка без применённых фильтров
  readonly totalPure: ValueModel<number | null> = new ValueModel<number | null>(null);

  constructor(
    { keys, entities }: { keys: C[]; entities: Map<C, T> } = {
      keys: [],
      entities: new Map<C, T>(),
    },
  ) {
    type PrivateFields = '_keys' | '_entities' | '_isAllLoaded';

    makeObservable<ListModel<T, C>, PrivateFields>(this, {
      _keys: observable,
      _entities: observable,
      _isAllLoaded: observable,

      reset: action,
      removeEntity: action,
      addEntity: action,
      addEntities: action,
      setIsAllLoaded: action,

      keys: computed,
      entities: computed,
      length: computed,
      items: computed,
      isAllLoaded: computed,
      hasNextPage: computed,
      isEmpty: computed,
    });

    this._keys = keys;
    this._entities = entities;
    this._isAllLoaded = false;
  }

  get keys(): C[] {
    return this._keys;
  }

  get entities(): Map<C, T> {
    return this._entities;
  }

  get items(): T[] {
    const arr: T[] = [];

    this._keys.forEach((id: C) => {
      const item = this._entities.get(id);

      if (item) {
        arr.push(item);
      }
    });

    return arr;
  }

  get length(): number {
    return this.items.length;
  }

  get isEmpty(): boolean {
    return this.items.length === 0;
  }

  get isAllLoaded(): boolean {
    return this._isAllLoaded;
  }

  get hasNextPage(): boolean {
    return !this._isAllLoaded;
  }

  setIsAllLoaded = (isAllLoaded: boolean) => {
    this._isAllLoaded = isAllLoaded;
  };

  // toJson(){
  //   const arr: T[] = [];

  //   this._keys.forEach((id: C) => {
  //     const item = this._entities.get(id);

  //     if (item && (item as any)?.toJson()) {
  //       arr.push((item as any).toJson());
  //     }
  //   });

  //   return arr;
  // }

  addEntity = ({ entity, key, start = false }: { entity: T; key: C; start?: boolean }): void => {
    this._entities.set(key, entity);

    if (start) {
      this._keys.unshift(key);
    } else {
      this._keys.push(key);
    }
  };

  updateEntity = ({ entity }: { entity: T }) => {
    this._entities.set((entity as T & { id: C }).id, entity);
  };

  addEntities = ({
    entities,
    keys,
    initial,
    start,
  }: {
    entities: Map<C, T>;
    keys: C[];
    initial: boolean;
    start?: boolean;
  }): void => {
    if (initial) {
      this._entities = entities;
      this._keys = keys;

      return;
    }

    keys.forEach((key) => {
      const entity = entities.get(key);

      if (!entity) {
        return;
      }
      this._entities.set(key, entity);
    });

    if (start) {
      this._keys.unshift(...keys);
    } else {
      this._keys.push(...keys);
    }
  };

  reset = (): void => {
    this._keys = [];
    this._entities = new Map();
  };

  removeEntity = (keyParam: C): void => {
    this._keys = this._keys.filter((key) => key !== keyParam);
    this._entities.delete(keyParam);
  };

  getEntity = (keyParam: C): T | null => {
    return this._entities.get(keyParam) || null;
  };

  getEntityByIndex = (index: number): T | null => {
    const key = this._keys[index];

    if (key === undefined) {
      return null;
    }

    return this.getEntity(key);
  };

  fillByRawData<S extends Record<string, any>>(
    raw: S[],
    normalizer: (raw: S) => { entity: T; key: C },
    initial = false,
  ): void {
    const keys: C[] = [];
    const entities: Map<C, T> = new Map();

    raw.forEach((item) => {
      const { entity, key } = normalizer(item);

      keys.push(key);
      entities.set(key, entity);
    });

    this.addEntities({
      entities,
      keys,
      initial,
    });
  }
}
