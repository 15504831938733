import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLotPageMultiStore } from 'stores/LotPageStore';
import s from './Appication.module.scss';
import Card from './Card';
import { BG_APP_IMAGES } from './configs';
import Slider, { SwiperInstance } from 'components/Slider';
import { useNavigate } from 'react-router-dom';
import { RouteSegment } from 'config/routes';
import { useRootStore } from 'stores/RootStore';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { ServicesSlugStatic } from 'entities/services/services';
import { useModalText } from 'components/ModalText/ModalText.context';

const BREAKPOINTS = {
  0: {
    slidesPerView: 2.2,
    spaceBetween: 8,
    pagination: true,
  },
  640: {
    slidesPerView: 1.5,
    spaceBetween: 8,
    pagination: true,
  },
  768: {
    slidesPerView: 2.2,
    spaceBetween: 16,
    pagination: true,
  },
  900: {
    slidesPerView: 2.5,
    pagination: true,
  },
  1280: {
    slidesPerView: 3,
    pagination: false,
  },
};

type ApplicationProps = {
  className?: string;
};

const key = 'lot-order';

const Application: React.FC<ApplicationProps> = ({ className }) => {
  const navigate = useNavigate();
  const { lot } = useLotPageMultiStore();
  const { profileService, userStore } = useRootStore();
  const [api, contextHolder] = notification.useNotification();
  const { toggleOpen, changeMsg } = useModalText();

  const openNotification = (success: boolean, message: string, description: string) => {
    api.info({
      message: message,
      description: description,
      placement: 'topRight',
      icon: success ? <CheckOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'red' }} />,
      key,
    });
  };

  const handleLoginButtonClick = () => {
    changeMsg('Для доступа ко всем возможностям зарегистрируйтесь или войдите в личный кабинет');
    toggleOpen(true);
  };

  const onSend = React.useCallback(
    (serviceSlug: string) => {
      if (!userStore.authorized) {
        handleLoginButtonClick();
      } else {
        const service = profileService.list.items.find((item) => item.slug === serviceSlug);
        if (userStore.user && service) {
          profileService.tariffStore
            .sendOrder({
              cost: service.cost || '0',
              user: userStore.user.userId,
              service: service.id,
              user_tariff: 1,
              lot: lot.value.id,
              slug: service.slug,
            })
            .then((res) => {
              if (res) {
                openNotification(true, 'Ваша заявка', 'была успешно отправлена!');
                profileService.addLotId({
                  lotId: lot.value.id,
                  serviceId: service.id,
                  slug: service.slug,
                });
              } else {
                openNotification(false, 'Ошибка!', 'Повторите через время.');
              }
            });
        }
      }
    },
    [lot, profileService.list.items, profileService.isLodingService, userStore.authorized],
  );

  const active = React.useMemo(() => {
    if (!userStore.authorized) {
      return {
        legal: false,
        online: false,
        audit: false,
      };
    }
    return {
      legal: profileService.checkLotInServices(lot.value.id, ServicesSlugStatic.escort),
      online: profileService.checkLotInServices(lot.value.id, ServicesSlugStatic.online),
      audit: profileService.checkLotInServices(lot.value.id, ServicesSlugStatic.audit_legal),
    };
  }, [
    lot.value.id,
    profileService.listServicesLotIds.value,
    profileService.isLodingService,
    profileService.checkLotInServices,
    userStore.authorized,
  ]);

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
  };

  return (
    <div className={className}>
      {contextHolder}
      <Slider
        className={s.swiper}
        spaceBetween={16}
        slidesPerView={3}
        pagination={pagination}
        // modules={[Pagination]}
        breakpoints={BREAKPOINTS}
        slides={[
          <Card
            className={s.card}
            title={'Онлайн-показ<br/>объекта'}
            description={''}
            onClick={() => onSend(ServicesSlugStatic.online)}
            isLoading={profileService.isLodingService}
            isApplicationSend={active.online}
            backgroundImage={BG_APP_IMAGES[0]}
            onClickMore={() => navigate('/' + RouteSegment.services_online + `?lot=${lot.value.id}`)}
          />,
          <Card
            className={s.card}
            title={'Юридический<br/>аудит'}
            description={''}
            onClick={() => onSend(ServicesSlugStatic.audit_legal)}
            isLoading={profileService.isLodingService}
            isApplicationSend={active.audit}
            backgroundImage={BG_APP_IMAGES[1]}
            onClickMore={() => navigate('/' + RouteSegment.services_audit_legal + `?lot=${lot.value.id}`)}
          />,
          <Card
            className={s.card}
            title={'Сопровождение<br/>сделки'}
            description={''}
            onClick={() => onSend(ServicesSlugStatic.escort)}
            isLoading={profileService.isLodingService}
            isApplicationSend={active.legal}
            backgroundImage={BG_APP_IMAGES[2]}
            onClickMore={() => navigate('/' + RouteSegment.services_escort + `?lot=${lot.value.id}`)}
          />,
        ]}
        onSlideChange={function (swiper: SwiperInstance): void {}}
      />
    </div>
  );
};

export default observer(Application);
