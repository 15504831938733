import React, { ReactNode } from 'react';
import s from './IconText.module.scss';

export const IconText = ({
  icon,
  text,
  onClick,
  className = '',
}: {
  icon?: ReactNode;
  text: string;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <div className={s.container + ' ' + className} onClick={onClick}>
      <span className={s.icon}>
        {icon || (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 18.0938C11.482 18.0938 11.0625 17.6742 11.0625 17.1562C11.0625 16.6383 11.482 16.2188 12 16.2188C12.518 16.2188 12.9375 16.6383 12.9375 17.1562C12.9375 17.6742 12.518 18.0938 12 18.0938ZM13.4742 12.9492C13.262 13.0312 13.0793 13.1752 12.9501 13.3625C12.821 13.5498 12.7512 13.7717 12.75 13.9992V14.5312C12.75 14.6344 12.6656 14.7188 12.5625 14.7188H11.4375C11.3344 14.7188 11.25 14.6344 11.25 14.5312V14.0273C11.25 13.4859 11.407 12.9516 11.7164 12.5062C12.0188 12.0703 12.4406 11.7375 12.9375 11.5477C13.7344 11.2406 14.25 10.5727 14.25 9.84375C14.25 8.81016 13.2398 7.96875 12 7.96875C10.7602 7.96875 9.75 8.81016 9.75 9.84375V10.0219C9.75 10.125 9.66562 10.2094 9.5625 10.2094H8.4375C8.33437 10.2094 8.25 10.125 8.25 10.0219V9.84375C8.25 8.92266 8.65312 8.0625 9.38437 7.42266C10.0875 6.80625 11.0156 6.46875 12 6.46875C12.9844 6.46875 13.9125 6.80859 14.6156 7.42266C15.3469 8.0625 15.75 8.92266 15.75 9.84375C15.75 11.1984 14.857 12.4172 13.4742 12.9492Z"
              fill="#1890FF"
            />
          </svg>
        )}
      </span>
      <p className={s.text}>{text}</p>
    </div>
  );
};
