import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import Tags from 'components/Tags';
import { FavoriteButton } from 'components/favoriteButtons/FavoriteButton';
import { DowngradeIcon } from 'components/icons/DowngradeIcon';
import SubscribeButton from 'components/subscribeButtons/SubscribeButton';
import { ElementId } from 'config/ids';
import { LotStatus, Status } from 'entities/lot';
import { useWidth } from 'hooks/useWidth';
import { ValueModel } from 'models/ValueModel';
import { useRootStore } from 'stores/RootStore';

import s from './Header.module.scss';

type HeaderProps = {
  status: LotStatus;
  auctionFormat: string;
  additionalTags?: Status[];
  auctionType: string;
  objectType: string;
  totalArea: string | null;
  priceMin: string | null;
  isDowngradeAuction: boolean;
  className?: string;
  isFavorite: boolean;
  handleFavoriteClick: (e: React.MouseEvent) => void;
  isSubscribed: boolean;
  handleSubscribeClick: (e: React.MouseEvent) => void;
};

const Aside = observer(
  ({
    isFavorite,
    handleFavoriteClick,
    isSubscribed,
    handleSubscribeClick,
    priceMin,
  }: {
    isFavorite: boolean;
    handleFavoriteClick: (e: React.MouseEvent) => void;
    isSubscribed: boolean;
    handleSubscribeClick: (e: React.MouseEvent) => void;
    priceMin: string | null;
  }) => {
    const { onboardingStore } = useRootStore();
    return (
      <div className={s.header__aside}>
        {onboardingStore.isOnboardingPassedModel.value && (
          <div className={s.header__buttons}>
            <SubscribeButton
              id={ElementId.lotCardSubscribeButtonDesktop}
              className={s['footer__subscribe-button']}
              onClick={handleSubscribeClick}
              isSubscribed={isSubscribed}
              variant="default"
              hideText
            />
            <FavoriteButton
              id={ElementId.lotCardFavoriteButtonDesktop}
              onClick={handleFavoriteClick}
              isFavorite={isFavorite}
              variant="default"
              hideText
            />
          </div>
        )}

        {priceMin && (
          <Typography.Paragraph className={cn(s['header__price'], s['header__text-accent'])}>
            {priceMin}
          </Typography.Paragraph>
        )}
      </div>
    );
  },
);

const Header = ({
  status,
  auctionFormat,
  auctionType,
  objectType,
  totalArea,
  priceMin,
  isDowngradeAuction,
  additionalTags,
  className,
  isFavorite,
  isSubscribed,
  handleFavoriteClick,
  handleSubscribeClick,
}: HeaderProps): React.ReactElement<HeaderProps> => {
  const { width } = useWidth();
  const isMobile = React.useMemo(() => {
    return width < 768;
  }, [width]);

  return (
    <div className={cn(s.header, className)}>
      <div className={s.header__main}>
        <Tags
          className={s['header__tags-container']}
          status={status}
          auctionFormat={auctionFormat}
          additionalTags={additionalTags}
        />
        {isMobile && (
          <Aside
            isFavorite={isFavorite}
            isSubscribed={isSubscribed}
            handleFavoriteClick={handleFavoriteClick}
            handleSubscribeClick={handleSubscribeClick}
            priceMin={priceMin}
          />
        )}
        <Typography.Title level={3} className={s['header__title']}>
          <span className={cn(s['header__title-object-type'], s['header__text-accent'])}>{objectType}</span>
          <AreaText area={totalArea} />
          {isDowngradeAuction && <DowngradeIcon className={s['header__downgrade-icon']} />}
        </Typography.Title>
        <Typography.Text className={s['header__auction-type']}>{auctionType}</Typography.Text>
      </div>
      {!isMobile && (
        <Aside
          isFavorite={isFavorite}
          isSubscribed={isSubscribed}
          handleFavoriteClick={handleFavoriteClick}
          handleSubscribeClick={handleSubscribeClick}
          priceMin={priceMin}
        />
      )}
    </div>
  );
};

export default React.memo(Header);
