import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import bgBottom from 'assets/banner-thre-new.png';
import bgTop from 'assets/banner-two-new.png';
import { Banner } from 'components/Banner';
import { PageContainer } from 'components/PageContainer';
import { routerUrls } from 'config/routes';
import { TariffsStoreProvider } from 'stores/TariffsStore/context';

import { Questions } from './Questions/Questions';
import { TariffsList } from './TariffsList/TariffsList';

import s from './TariffsPage.module.scss';
import { useRootStore } from 'stores/RootStore';
import { notification } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { TariffPricingBillingCycle } from 'entities/tariffs';
import { AnalyticsEvent } from 'entities/analytics';

type TariffsPageProps = {
  className?: string;
};

const key = 'TariffsPage';

const TariffsPage = ({ className }: TariffsPageProps): React.ReactElement<TariffsPageProps> => {
  const navigate = useNavigate();
  const { tariffsStore, userStore, analyticsStore, authStore } = useRootStore();

  const [api, contextHolder] = notification.useNotification();

  const notificationShow = (title: string, description: string, isError?: boolean) => {
    api.info({
      message: title,
      description: description,
      placement: 'topRight',
      icon: isError ? <WarningFilled style={{ color: 'red' }} /> : <CheckCircleFilled style={{ color: 'green' }} />,
      key,
    });
  };

  const handleLoginButtonClick = React.useCallback(() => {
    authStore.popupController.open();
    authStore.mode.change('login');
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const onSubscription = () => {
    const premium = tariffsStore.tariffs.items.find((item) => item.name === 'Premium');
    const option = (premium?.pricing_options || []).find(
      (item) => item.billing_cycle === TariffPricingBillingCycle.month,
    );
    if (userStore.user?.userId && premium && option) {
      tariffsStore
        .subscriptionTariff({
          user: userStore.user?.userId,
          plan_option: option.id,
          tariff: premium.id,
        })
        .then((res) => {
          if (!res.isError) {
            window.location.href = res.data.payment_url;
          } else {
            notificationShow('Ошибка!', res?.data || 'Error 500', true);
          }
        });
    } else {
      handleLoginButtonClick();
    }
  };
  return (
    <TariffsStoreProvider>
      <PageContainer className={cn(s['lot-page'], className)}>
        {contextHolder}
        <Banner
          title="Пакеты услуг"
          description="Выберите идеальный пакет услуг для ваших потребностей и получите доступ к широким возможностям платформы"
          backgroundImage={bgTop}
          className={s.bgTop}
        />
        <TariffsList />
        <Banner
          title="Premium"
          description="Максимум возможностей: полный доступ к объектам, приоритетная поддержка, скидки на юридические услуги и онлайн-показы"
          backgroundImage={bgBottom}
          className={s.bgBottom}
          actions={[
            {
              label: 'Купить',
              onClick: () => {
                onSubscription();
              },
              className: s.bannerBtn,
            },
            {
              label: 'Узнать подробности',
              onClick: () => {
                navigate(routerUrls.tariffsPremium.mask);
              },
              className: s.bannerBtn,
            },
          ]}
        />
        <Questions />
      </PageContainer>
    </TariffsStoreProvider>
  );
};

export default observer(TariffsPage);
