import { Modal, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import CloseRoundIcon from 'components/icons/CloseRound';
import LogoIcon from 'components/icons/Logo';
import { AuthStep } from 'stores/AuthStore';
import { useRootStore } from 'stores/RootStore';

import ConfirmStep from './ConfirmStep/ConfirmStep';
import InitialStep from './InitialStep/InitialStep';
import SuccessStep from './SuccessStep/SuccessStep';
import { useSwipeable } from 'react-swipeable';

import s from './AuthPopup.module.scss';

const AuthPopup: React.FC = () => {
  const { authStore } = useRootStore();
  const { authStep, popupController } = authStore;
  const handlers = useSwipeable({
    onSwipedDown: () => {
      popupController.close();
    },
    delta: { down: 100 },
  });
  return (
    <Modal
      classNames={{ body: s.popup__body, content: s.popup__card }}
      className={s.popup}
      open={popupController.value}
      footer={false}
      closeIcon={<CloseRoundIcon className={s.popup__close} />}
      width={447}
      onCancel={popupController.close}
      centered
    >
      <div {...handlers}>
        <Space className={s.popup__content} direction="vertical" size={24}>
          <LogoIcon className={authStep.value === AuthStep.success ? s.popup__logo__success : s.popup__logo} />
          {authStep.value === AuthStep.initial ? (
            <InitialStep />
          ) : authStep.value === AuthStep.confirm ? (
            <ConfirmStep />
          ) : (
            <SuccessStep />
          )}
        </Space>
      </div>
    </Modal>
  );
};

export default observer(AuthPopup);
