import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { LotStatus } from 'entities/lot';
import { useLotPageMultiStore } from 'stores/LotPageStore';

import { Timeline } from './Timeline';

import s from './MobileTimeline.module.scss';

type MobileTimelineProps = {
  className?: string;
};

const MobileTimeline: React.FC<MobileTimelineProps> = ({ className }) => {
  const { lot } = useLotPageMultiStore();
  const { status } = lot.value;

  if (status === LotStatus.canceled) {
    return null;
  }

  return (
    <div className={cn(s['mobile-timeline'], className)}>
      <Timeline />
    </div>
  );
};

export default observer(MobileTimeline);
