import * as React from 'react';

import { ElementId } from 'config/ids';

import Section from '../Section';
import SectionBlock from '../SectionBlock';

type PropertySectionProps = {
  lot: {
    totalAreaFormatted?: null | string;
    floor?: null | string;
    objectType: string;
  };
  id?: ElementId;
  hide?: boolean;
  print?: boolean;
};

const PropertySection: React.FC<PropertySectionProps> = ({ lot, id, hide = true, print = false }) => {
  return (
    <Section id={id} title="Характеристики имущества" className={'section-navigation ' + id} grid={print}>
      {lot.totalAreaFormatted && (
        <SectionBlock title="Площадь объекта" description={`${lot.totalAreaFormatted} м²`} hide={hide} />
      )}
      {lot.floor && <SectionBlock title="Этаж" description={lot.floor} hide={hide} />}
      <SectionBlock title="Тип объекта" description={lot.objectType} hide={false} />
    </Section>
  );
};

export default React.memo(PropertySection);
