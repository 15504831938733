import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { ILotBase, Status } from 'entities/lot';
import useToggleState from 'hooks/useToggleState';
import { LotModel } from 'models/LotModel';

import { ImageGallery } from '../ImageGallery';
import Layout from '../Layout';
import { Meta } from '../Meta';

import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';

import s from './LotCard.module.scss';
import { ValueModel } from 'models/ValueModel';
import { useModalText } from 'components/ModalText/ModalText.context';

export type LotCardSmallItemData<T> = T &
  ILotBase & {
    startToggleFavorite?: () => void;
    startToggleSubscription?: () => void;
    priceMinFormatted?: string | null;
    totalAreaFormatted?: string | null;
    isInFavorite?: ValueModel<boolean>;
    isInSubscribed?: ValueModel<boolean>;
    lotCardAddress?: string | null;
  };

export type LotCardProps<T> = {
  item: LotCardSmallItemData<T>;
  additionalTags?: Status[];
  style?: React.CSSProperties;
  className?: string;
  mobileHover?: boolean;
  auth?: boolean;
};

export const LotCardIntersection = observer(
  <T,>(props: LotCardProps<T> & { onView?: (id: number | number[]) => void }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });
    const refStop = React.useRef(false);

    React.useEffect(() => {
      if (inView && !refStop.current && props.onView) {
        refStop.current = true;
        props.onView(props.item.id);
      }
    }, [inView]);

    return (
      <div ref={ref}>
        <LotCard {...props} />
      </div>
    );
  },
);

const LotCard = <T,>({
  item,
  className,
  style,
  additionalTags,
  mobileHover,
  auth,
}: LotCardProps<T>): React.ReactElement<LotCardProps<T>> => {
  const { open, close } = useToggleState();
  const { changeMsg, toggleOpen } = useModalText();

  const handleFavoriteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      console.log(item.startToggleFavorite);
      if (item.startToggleFavorite) {
        item.startToggleFavorite();
      } else {
        changeMsg('Для доступа ко всем возможностям зарегистрируйтесь или войдите в личный кабинет');
        toggleOpen(true);
      }
    },
    [item],
  );

  const handleSubscribeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (item.startToggleSubscription) {
        item.startToggleSubscription();
      } else {
        changeMsg('Для доступа ко всем возможностям зарегистрируйтесь или войдите в личный кабинет');
        toggleOpen(true);
      }
    },
    [item],
  );

  const images = item.images ? item.images.slice(0, 4) : [];

  return (
    <Layout
      item={item as unknown as LotModel}
      style={style}
      className={className}
      handleMouseEnter={open}
      handleMouseLeave={close}
      mobileHover={mobileHover}
    >
      <ImageGallery images={images} className={s.image + ' lot-card-image'} showBullets />
      <div className={s['lot-card__main-content']}>
        <Header
          status={item.status}
          auctionFormat={item.auctionFormat}
          auctionType={item.auctionType}
          objectType={item.objectType}
          priceMin={item?.priceMinFormatted || null}
          totalArea={item?.totalAreaFormatted || null}
          isFavorite={item?.isInFavorite?.value || false}
          handleFavoriteClick={handleFavoriteClick}
          isSubscribed={item?.isInSubscribed?.value || false}
          handleSubscribeClick={handleSubscribeClick}
          additionalTags={additionalTags}
          isDowngradeAuction={item.isDowngradeAuction}
        />
        <Main address={item.lotCardAddress} source={item.source} column auth={auth} />
        <Meta floor={item.floor} totalFloors={item.amountOfFloors} entranceCount={item.entrance} />
        <Footer dateAdded={item.dateAdded} viewsCount={item.viewsCount} />
      </div>
    </Layout>
  );
};

export default observer(LotCard);
