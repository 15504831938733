import React from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import LotPageTags from 'pages/LotPage/LotPageTags';
import { HeaderActions } from '../Header/Header';
import s from './MobileHeader.module.scss';
import cn from 'classnames';
import { useLotPageMultiStore } from 'stores/LotPageStore';

const MobileHeader = ({ className }: { className?: string }) => {
  const { lot } = useLotPageMultiStore();
  return (
    <div className={cn(s['lot-header-mobile'], className)}>
      <LotPageTags />
      <div className={s['lot-header-mobile-actions']}>
        <Typography.Paragraph className={cn(s['lot-header-mobile__price'], s['lot-header-mobile__text-accent'])}>
          {lot.value?.priceMinFormatted || 0}
        </Typography.Paragraph>
        <HeaderActions showPrintPdf={true} />
      </div>
    </div>
  );
};

export default observer(MobileHeader);
